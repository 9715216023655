@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
	// box-sizing: unset !important;
}

body {
	margin: 0;
	font-family: 'Open Sans', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.info-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 10px;

	.info-header-right {
		.info-header-right-title {
			font-size: 14px;
			text-transform: uppercase;
			padding-bottom: 10px;
			color: #26a4ed;
			letter-spacing: 2px;
			font-weight: 700;
		}

		.info-header-right-search {
			display: flex;
			align-items: center;

			> input {
				margin-right: 5px;
			}

			.info-header-right-search-icon {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 7px;
				background-color: #dff3ff;
				border-radius: 50%;
				cursor: pointer;
			}
		}
	}
	.info-header-left {
		display: flex;

		.info-header-left-add {
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #dff3ff;
			border-radius: 50%;
			margin-right: 10px;
			cursor: pointer;
		}

		.info-header-left-edit {
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #dff3ff;
			border-radius: 50%;
			margin-right: 10px;
			cursor: pointer;
		}

		.info-header-left-delete {
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #ffdfdf;
			border-radius: 50%;
			cursor: pointer;
		}
	}
}

.info-header-title {
	font-size: 14px;
	text-transform: uppercase;
	padding-bottom: 10px;
	color: #26a4ed;
	letter-spacing: 2px;
	font-weight: 700;
}

.visible-icon {
	width: 30px;
	height: 30px;
}

.visible-block {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
}

.save-toast {
	display: flex;
	justify-content: flex-end;
	margin-top: 15px;

	&-yes, &-no {
		padding: 5px 10px;
		border-radius: 8px;
		font-weight: bold;
	}

	&-yes {
		margin-right: 15px;
		background-color: #66e1838b;
	}

	&-no {
		background-color: #e0615f8b;
	}
}

// .radio-cell {
// 	// display: flex;
// 	// align-items: center;
// 	// justify-content: center;
// 	width: 36px;
// 	height: 36px;
// }

// .radio-wrapper {
// 	width: 16px;
// 	height: 16px;
// }

.rs-radio-wrapper [type=radio] {
	// position: unset !important;
	z-index: 0 !important;
}

.reset-user-password {
	padding: 5px;
	border-radius: 6px;
	color: #26a4ed;
	background: white;
	border: 1px solid #26a4ed;
}