@import url('rsuite/dist/rsuite-no-reset.css');

.info-container {
	position: absolute;
	// height: calc(100vh - 55px);
	right: 0;
	width: 75%;
	padding: 30px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box !important;
	overflow: auto;
}

.treatment-addons-title {
	color: #26a4ed;
}

.treatment-addon-delete {
	padding: 2px;
	border-radius: 50%;
	// background-color: gray;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: none;
}

.option {
	display: flex;
	margin: 5px;
	padding: 5px;
}

.treatment-addons-wrapper {
	display: flex;
	margin-top: 15px;
}

.treatment-addon-wrapper {
	justify-content: space-between;
	display: flex;
	min-width: 150px;
	background-color: #f3f3f3;
	margin-right: 15px;
	padding: 5px;
}

.treatment-addon-wrapper-details {
	margin-right: 10px;
}

.treatment-addon-details-title {
	font-weight: bold;
	font-size: 16px;
}

.treatment-addon-details-fee {
	font-size: 14px;
}

.treatment-addons-header {
	display: flex;
    justify-content: space-between;
    align-items: center;
}

.treatment-addons-title-edit {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #dff3ff;
	border-radius: 50%;
	margin-right: 10px;
	cursor: pointer;
}
