
.clinic-title {
    display: flex;
    margin-bottom: 50px;

    > img {
        margin-right: 50px;  
    }

    .clinic-title-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        > p {
            // padding: 20px 0;

            > p {
                margin: 0;
            }
        }
    }
}

.clinic-info-string-title-gray {
    opacity: 0.7;
}

.clinic-info-string {
    width: 85%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #BCBDBE;
    justify-content: space-between;

    > a {
        padding: 10px 0;
        color: #26A4ED;
        text-decoration: none;
    }

    .clinic-info-string-details {
        display: flex;
        align-items: center;
        margin: 0;
        color: #212529;
        
        > p {
            color: #26A4ED;
            padding: 10px 0;
            margin: 0;
        }
    }

    .clinic-info-string-details-website {
        display: flex;
        align-items: center;
        margin: 0;
        color: #26A4ED
    }

    .clinic-info-string-phone {
        margin: 0;
        padding: 10px 0;
    }

    .clinic-info-string-date {
        color: #26A4ED;
    }
}

.edit-input {

    &-wrapper {
        display: flex;
        align-items: center;
    }

    &-title, &-content, &-number {
        border-radius: 5px;
        border: 2px solid #7D8485;
    }

    &-title {      
        width: 100%;
    }

    &-content {

    }

    &-number {
        width: 50px;
    }
}

.cards-list-list {
    display: flex;
    align-items: center;
}

.website {
    margin: 10px 0;
}

.offices {
    width: 500px;
}

.phone {
    margin: 10px 5px;
}

.edit-button {
    margin: 0 5px;
    background-color: #DFF3FF;
    border-radius: 4px;
    width: 25px;
    height: 28px;
    color: #26A4ED;
    font-weight: 700;
    text-align: center;
}

.input-logo-wrapper {
    cursor: poiner;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DFF3FF;
    margin-right: 25px;
    width: 200px;
    padding: 10px;
}

.input-logo-block {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    border: 1px dashed gray;
}

.image-prewiew {
    background-color: #DFF3FF;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
}

.input-logo {
    width: 0;
    height: 0;
    cursor: pointer;
}

iframe#webpack-dev-server-client-overlay{display:none!important}