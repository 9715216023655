.payment-wrapper {
  width: 100%;
  height: 100vh;
}

.payment-block,
.payment-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-block {
  flex-direction: column;
  width: 100%;
  // max-width: 750px;

  > p {
    margin: 0;
  }
}

.payment-block-logo {
  width: 150px;
  height: 150px;
  margin-bottom: 50px;
}

.payment-block-text {
  width: 80%;
  max-width: 560px;
  margin-bottom: 25px;

  > p {
    margin: 0;
  }
}

.payment-block-title,
.payment-block-details {
  font-size: 30px;
  font-weight: bold;
}

.payment-block-title {
  width: 100%;
  color: #26a4ed;
}

.payment-block-yes-button,
.payment-block-thinking-button {
  width: 360px;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 10px;
  color: #26a4ed;
  font-weight: bold;
  padding: 20px;
}

.payment-block-yes-button {
  background-color: #dff3ff;
}

.payment-block-thinking-button {
  border: 1px solid #26a4ed;
  background-color: white;
}

.payment-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.payment-form-header {
  display: flex;
  align-items: center;
  margin: 50px 0 100px 0;
}

.payment-form-header-logo {
  margin: 0 30px;
  width: 150px;
  height: 150px;
}

.payment-form-header-text {
  font-size: 30px;
  font-weight: bold;
}

.payment-form {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #26a4ed;
}

.payment-form-buttons {
  margin-top: -40px;
}

.payment-form-button {
  width: 300px;
  height: 80px;
  background-color: white;
  border: 2px solid #26a4ed;
  color: #26a4ed;
  font-weight: bold;
  font-size: 18px;
}

.active-button {
  background-color: #26a4ed !important;
  color: white !important;
}

.pay-button {
  margin: 60px;
  width: 160px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dff3ff;
  color: #26a4ed;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
}

.success-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.payment-success-icon,
.payment-error-icon {
  width: 150px;
  height: 150px;
}

.success-next-button {
  width: 200px;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 10px;
  color: #26a4ed;
  font-weight: bold;
  padding: 20px;
  border: 1px solid #26a4ed;
  background-color: white;
}

@media (max-width: 800px) {
  .payment-block-thinking-button {
	width: 200px;
  }

  .payment-block-yes-button {
	width: 200px;
  }

  .payment-block-text > p {
	text-align: center;
  }

  .payment-block-title {
	padding: 20px 0;
  }

  .payment-block-details {
	font-size: 20px;
  }
}

@media (max-width: 450px) {
  .payment-block-buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }
}
