.main-container {
    width: 100%;
    // height: 100vh;

    .main-content{
        margin-top: 55px;
        width: 100%;
        height: calc(100vh - 55px);
        display: flex;
    }
}
