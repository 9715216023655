.main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100vh;
	padding: 50px 0;

	& p {
		width: 40%;
		text-align: center;
		font-size: 22px;
		font-weight: bold;
	}
}

@media (max-width: 980px) {
	.main > p {
		width: 90%;
	}
}