.login-form-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	.login-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 80%;

		.login-form-title {
			text-transform: uppercase;
			color: #26A4ED;
			font-size: 30px;
		}
	
		.login-form-input {
			background-color: none;
			border: none;
			border-bottom: 3px solid #7D8485;
			width: 100%;
			height: 35px;
			margin-bottom: 50px;
			outline: none;
			font-size: 20px;
		}
	
		.login-form-button {
			text-align: center;
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-between;
			background-color: #DFF3FF;
			color: #26A4ED;
			border: none;
			height: 70px;
			font-size: 22px;
			font-weight: bold;
			margin: 60px 0 0 0;
		}
	}
}



