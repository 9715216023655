.wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.contract-wrapper {
	width: 100%;
	background-color: lightgray;
	// height: 300px;
	border: 1px solid black;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 0;
}

.download-section {
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.download-link {
	width: 160px;
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #DFF3FF;
	color: #26A4ED;
	font-weight: bold;
	text-decoration: none;
	border-radius: 10px;
}

.next-link {
	margin-left: 25px;
}