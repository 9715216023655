.login-container {
    width: 100%;
    // height: 100vh;
    display: flex;
    justify-content: space-between;

    .login-form-container {
        width: 40%;

        @media (max-width: 850px) {
            width: 100%;
        }
    }

    .login-img {
        width: 60%;
        height: 100vh;    
        clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);

        @media (max-width: 850px) {
            display: none;
        }
    }
}
