.patient-info {
	&-container {
		padding: 25px 50px;
		width: calc(100% - 500px);
		letter-spacing: 1.2px;
		overflow: auto;
	}

	&-header {
		display: flex;
		justify-content: space-between;

		&-icons {
			display: flex;
			align-items: center;

			&-edit,
			&-delete {
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}

			&-edit {
				margin-right: 25px;
				background-color: #dff3ff;
				border-radius: 50%;
			}

			&-delete {
				background-color: #ffdfdf;
				border-radius: 50%;
			}
		}
	}

	&-block {
		display: flex;
		flex-direction: column;
		margin-bottom: 25px;

		&-titles {
			margin-right: 25px;
			display: flex;
			flex-direction: column;

			&-edit {
				margin-right: 25px;
				display: flex;
				flex-direction: column;

				> b {
					padding-bottom: 10.5px;
					font-size: 14px;
				}
			}

			> b {
				padding-bottom: 5px;
				font-size: 14px;
			}
		}

		&-details {
			> p {
				font-size: 14px;
				padding-bottom: 5px;
				margin: 0;
			}
		}
	}

	&-details {
		display: flex;
		justify-content: space-between;
		width: 90%;
		padding-bottom: 15px;

		&-insurance {
            width: 50%;
			&-header {
				font-size: 15px;
				text-transform: uppercase;
				color: #26a4ed;
				padding-bottom: 10px;
			}

			&-info {
				display: flex;
                justify-content: space-between;

				&-titles {
					margin-right: 25px;
					display: flex;
					flex-direction: column;

                    &-edit {
                        margin-right: 25px;
                        display: flex;
                        flex-direction: column;
        
                        > b {
                            padding-bottom: 10.5px;
                            font-size: 14px;
                        }
                    }

					> b {
						font-size: 14px;
						padding-bottom: 5px;
					}
				}

				&-details {
                    width: 40%;
					> p {
						font-size: 14px;
						padding-bottom: 5px;
						margin: 0;
					}
				}
			}
		}

		&-payment {
            width: 50%;

			&-header {
				font-size: 15px;
				text-transform: uppercase;
				color: #26a4ed;
				padding-bottom: 5px;
			}

			&-info {
				display: flex;
                justify-content: space-between;

				&-titles {
					display: flex;
					flex-direction: column;

                    &-edit {
                        margin-right: 25px;
                        display: flex;
                        flex-direction: column;
        
                        > b {
                            padding-bottom: 10.5px;
                            font-size: 14px;
                        }
                    }

					> b {
						font-size: 14px;
						padding-bottom: 5px;
					}
				}

				&-details {
                    width: 30%;
					> p {
						font-size: 14px;
						padding-bottom: 5px;
						margin: 0;
					}
				}
			}
		}
	}

	&-complex {
		margin-top: 15px;
		margin-bottom: 20px;

		&-header {
			display: flex;
			color: #26a4ed;
			padding: 5px;
			margin-left: 10px;
			border: 3px solid #dff3ff;
			border-bottom: none;
			width: fit-content;

			> p,
			b {
				font-size: 14px;
			}

			> p {
				margin: 0;
				padding-left: 5px;
			}
		}

		&-elements {
			padding: 15px 30px;
			border: 3px solid #dff3ff;
			margin-bottom: 15px;

			&-card {
				width: 35%;
			}

			&-card-edit {
				width: 45%;
				max-width: 500px;
			}

			&-card, &-card-edit {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 5px;
				background-color: #f3f3f3;
				margin-bottom: 10px;
				box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);

				&-main {
					&-title {
						font-size: 13px;
					}

					&-price {
						font-size: 13px;
					}
				}

				&-required,
				&-optional {
					font-size: 13px;
					padding: 3px;
					padding-top: 0;
					color: #fff;
					margin-right: -40px;
				}

				&-required {
					background-color: #26a4ed;
				}

				&-required-edit {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 10px;

					> p {
						margin: 0;
						margin-right: 10px;
						font-size: 13px;
						opacity: 0.7;
					}

					> svg {
						// margin-left: 25px;
						cursor: pointer;
					}
				}

				&-optional {
					background-color: #7d8485;
				}
			}

			&-container {
				width: 100%;
				display: flex;
				justify-content: flex-end;

				&-button {
					width: fit-content;
					font-weight: bold;
					padding: 10px 15px;
					border-radius: 4px;
					background-color: #dff3ff;
				}
			}
		}
	}

	&-bottom {
		width: 100%;
		display: flex;
		justify-content: center;

		&-button {
			color: #26a4ed;
			padding: 10px 100px;
			background-color: #dff3ff;
			letter-spacing: 2px;
			border-radius: 6px;
		}
	}
}

.input-main-info, .input-title {
	border-radius: 5px;
	border: 2px solid #7d8485;
	width: 170px;
}

.treatment-input {
	margin-bottom: 15px;
}

.input-mini {
    width: 50%;
}

.input-medium {
    width: 90%;
}

.input-title {
    width: 30%;
}

.input-firstName {
    margin-right: 10px;
}

.input-checkbox-edit {
	margin: 10px 0;
}

.delete-addons {
	margin-left: 25px;
	width: 25px;
	height: 25px;
	cursor: pointer;

	> svg {
		width: 25px;
		height: 25px;
	}
}

.delete-treatment {
	margin-left: 25px;
	margin-right: 10px;
	width: 15px;
	height: 15px;
	cursor: pointer;

	> svg {
		width: 20px;
		height: 20px;
	}
}

.download-contract-block {
	display: flex;
}

.download-contract-item {
	width: 350px;
	padding: 20px;
	border: 1px solid #26A4ED;
	border-radius: 10px;
	margin-right: 20px;
}

.download-contract-row {
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.download-contract-title {
	margin: 0;
	font-weight: bold;
}

.download-contract-button-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.download-monthly-contract-button-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.download-contract-button {
	color: #26a4ed;
    padding: 10px 30px;
    background-color: #dff3ff;
    letter-spacing: 2px;
	border-radius: 6px;
}

.download-contract-empty {
	width: 30px;
}

.required {
	color: red;
}

.patient-info-block-row, .patient-info-block-row-edit {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	width: 600px;
	justify-content: space-between;
	margin-bottom: 5px;
	font-size: 14px;

	& p {
		margin: 0;
	}
}

// .patient-info-block-row-edit {
// 	width: 350px;
// }

// .patient-info-block-row {
// 	width: 250px;
// }
