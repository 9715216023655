.present-sidebar {
	width: 100%;
	max-width: 280px;
	display: flex;
	flex-direction: column;
	border-right: 3px solid #dff3ff;
	padding: 20px 10px;
	height: 100vh;
	overflow-y: hidden;
	position: relative;
	z-index: 10;
}

.present-sidebar-info-title {
	text-align: center;
	font-size: 18px;
	color: #26A4ED;
	margin-bottom: 15px;
}

.present-sidebar-info-cards {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.present-card {
	width: 85%;
	text-decoration: none;
	color: black;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border: 3px solid #26A4ED;
	margin-bottom: 20px;
}

.present-sidebar-info-card-info {
	> p {
		margin: 5px 0;
	}
}

@media (max-width: 980px) {
	.present-sidebar {
		display: none;
	}

	.present-sidebar-open {
		position: absolute;
		width: calc(100vw - 24px);
		right: 0;
		background-color: white;
		height: 100vh;
		z-index: 100;
		padding: 20px 0;
	}

	.open-sidebar {
		position: relative;
		left: 0;
		z-index: 101;
	}
}

@media (min-width: 980px) {
	.mobile-sidebar {
		display: none;
	}
}