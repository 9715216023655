$title-size: 16px;
$table-title-size: 15px;

// p {
// 	margin: 0;
// }

.wrapper {
	width: 90%;
	background: white;
	padding: 20px;
	display: flex;
	justify-content: center;
}

.contract_wrapper {
	width: 90%;
}

.title {
	font-size: $title-size;
	margin-bottom: 15px;
	text-align: center;
	margin: 0;
}

.orthodontist_wrapper {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 25px;
}

.signature_info_wrapper {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.orthodontist_right,
.orthodontist_left {
	display: flex;
}

.orthodontist_left_name,
.orthodontist_right_name {
	margin-right: 15px;
}

.orthodontist_left_underline,
.orthodontist_right_underline {
	border-bottom: 1px solid black;
}

.orthodontist_left_underline {
	width: 275px;
}

.orthodontist_right_underline {
	width: 120px;
}

.details_table {
	width: 100%;
	margin-bottom: 25px;
}

.details_top_row {
	width: 100%;
	display: flex;
}

.details_top_right_row_content,
.details_top_left_row_content {
	width: 50%;
	padding: 5px 10px;
	border-top: 1px solid black;
	border-left: 1px solid black;
}

.details_bottom_right_row_content,
.details_bottom_left_row_content {
	width: 50%;
	padding: 5px 10px;
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-bottom: 1px solid black;
}

.details_top_left_row_content,
.details_bottom_left_row_content {
	border-right: 1px solid black;
}

.details_row_content_title {
	font-size: $table-title-size;
	font-weight: bold;
	margin-bottom: 5px;
	margin: 0;
}

.details_row_content_text {
	font-size: $table-title-size;
	margin: 0;
}

.payment_table {
	width: 100%;
	margin-bottom: 25px;
}

.payment_table_row {
	display: flex;
}

.payment_table_row_min_cell,
.payment_table_row_big_cell,
.payment_table_bottom_row_min_cell {
	padding: 10px;
	font-size: $table-title-size;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.payment_table_row_min_cell,
.payment_table_row_big_cell {
	background-color: #d9d9d9;
	font-weight: bold;
}

.payment_table_row_min_cell,
.payment_table_bottom_row_min_cell {
	width: 16.6%;
	height: 65px;
	border-top: 1px solid black;
	border-left: 1px solid black;
	// border-bottom: 1px solid black;
}

.payment_table_row_big_cell {
	width: 33.6%;
	height: 65px;
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-right: 1px solid black;
}

.payment_table_bottom_row_min_cell, .payment_table_bottom_mini_bottom_cell {
	border-bottom: 1px solid black;
}

.payment_table_bottom_row_big_cell {
	width: 33.6%;
}

.payment_table_bottom_mini_top_row,
.payment_table_bottom_mini_bottom_row {
	width: 100%;
	height: calc(65px / 2);
	border-top: 1px solid black;
	border-left: 1px solid black;
}

.payment_table_bottom_mini_top_cell,
.payment_table_bottom_mini_bottom_cell {
	width: 100%;
	display: flex;
}

.payment_table_bottom_mini_top_cell {
	background-color: #d9d9d9;
	height: 100%;
}

.payment_table_bottom_mini_bottom_cell {
	height: 100%;
}

.payment_table_bottom_mini_top_cell_title, .payment_table_bottom_mini_bottom_cell_title {
	height: 100%;
	padding: 4px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 33.3%;
	border-right: 1px solid black;
	margin: 0;
}

.primary_title {
	margin-bottom: 15px;
	font-weight: bold;
	font-size: $table-title-size;
}

.primary_table {
	width: 100%;
	margin-bottom: 20px;	
}

.primary_title_row {
	width: 100%;
	padding: 5px 0;
	font-size: $table-title-size;
	font-weight: bold;
	background-color: #d9d9d9;
}

.primary_title_text {
	margin: 0;
}

.primary_title_row, .primary_bank_row, .primary_card_row {
	border: 1px solid black;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.primary_bank_row, .primary_card_row {
	border-top: none;
	padding: 20px 0;
	height: 175px;
}

.primary_bank_block, .primary_card_block {
	width: 60%;
}

.primary_bank_checkbox_row {
	width: 60%;
	display: flex;
	justify-content: space-between;
	font-size: $table-title-size;
	margin-bottom: 15px;
	
}

.primary_bank_checkbox_text,.primary_bank_name_underline, .primary_bank_name_text, .primary_bank_numbers_underline, .primary_bank_numbers_text, .primary_card_checkbox_text, .primary_card_text, .primary_card_underline, .primary_card_second_text, .primary_card_second_underline, .subtitle, .signature_text, .signature_underline, .date_text, .date_underline, .address_left, .address_right {
	margin: 0;
}

.primary_bank_checkbox_block {
	display: flex;
	align-items: center;
}

.primary_bank_checkbox {
	width: 15px;
	height: 15px;
	margin-right: 15px;
	border: 1px solid black;
	display: flex;
	align-items: center;
	justify-content: center;
}

.primary_bank_name_row {
	margin-bottom: 15px;
}

.primary_bank_name_underline {
	width: 100%;
	height: 22px;
	border-bottom: 1px solid black;
}

.primary_bank_name_text, .primary_bank_numbers_text {
	font-size: 13px;
}

.primary_bank_numbers_block {
	// display: flex;
	width: 40%;
}

.primary_bank_numbers_row {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.primary_bank_numbers_underline {
	width: 100%;
	height: 22px;
	border-bottom: 1px solid black;
}


.primary_card_first {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}

.primary_card_left {
	display: flex;
	align-items: center;
}

.primary_card_text {
	margin-right: 20px;
}

.primary_card_underline {
	border-bottom: 1px solid black;
	width: 250px;
}

.primary_card_underline {
	height: 23px;
}

.primary_card_second, .primary_card_third {
	width: 100%;
	display: flex;
	justify-content: space-between;
	font-size: $table-title-size;
}

.primary_card_second {
	margin-bottom: 15px;
}

.primary_card_second_text {
	margin-right: 15px;
}

.primary_card_second_underline {
	border-bottom: 1px solid black;
	width: 70%;
}

.subtitle {
	width: 100%;
	font-size: 12px;
	margin-bottom: 15px;
}

.signature, .date {
	width: 30%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 15px;
}

.signature {
	height: 50px;
}

.signature_text {

}

.signature_underline, .date_underline {
	border-bottom: 1px solid black;
}

.signature_underline {
	width: calc(100% - 90px);
}

.date_underline {
	width: calc(100% - 55px);
}

.oid_table {
	width: 60%;	
	margin-bottom: 15px;
}

.oid_table_title_row {
	width: 100%;
	font-size: $table-title-size;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #d9d9d9;
	padding: 5px 0;
	border: 1px solid black;
}

.oid_table_row {
	width: 100%;
	display: flex;
}

.oid_table_row_left, .oid_table_row_right {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	border-left: 1px solid black;
	border-bottom: 1px solid black;
}

.oid_table_row_right {
	border-right: 1px solid black;
}

.address {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	font-size: $table-title-size;
}

.contacts {
	font-size: $table-title-size;
	margin-bottom: 30px;
}


