.cons-sidebar-container {
	width: 500px;
	height: calc(100vh - 55px);
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 10;
	border-right: 3px solid #dff3ff;

	.cons-sidebar-header {
		width: 100%;
		padding: 20px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
		margin-bottom: 20px;

		.cons-sidebar-header-search {
			// width: 60%;
			display: flex;
			border: 1px solid #7d8485;
			padding-left: 10px;

			> input,
			.cons-sidebar-header-search-select > select {
				border: none;
				height: 40px;
			}

			> input {
				width: 100%;
			}

			.cons-sidebar-header-search-select {
				background-color: #f3f3f3;
				color: #7d8485;

				select {
					background-color: #f3f3f3;
					padding: 0 10px;
				}
			}

			> input:focus,
			.cons-sidebar-header-search-select > select:focus {
				border: none;
				outline: none;
			}
		}

		.cons-sidebar-header-icons {
			// width: 35%;
			display: flex;
			align-items: center;
			margin-left: 15px;
			justify-content: space-between;

			.cons-sidebar-header-icons-wrapper {
				width: 42px;
				height: 42px;
				margin: 0 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				background-color: #dff3ff;
				cursor: pointer;
			}
		}
	}

	.cons-sidebar-cards {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: calc(100vh - 145px);
		overflow: auto;

		.card {
			text-decoration: none;
			color: #212529;
			width: 75%;
			border: 3px solid #dff3ff;
			display: flex;
			flex-direction: column;
			margin: 10px 0;
		}

		.card-active {
			border: 3px solid #26a4ed !important;
			box-shadow: 0px 0px 10px 0px #26a4ed;
		}

		.card-edit {
			border: 3px solid #f15757 !important;
			box-shadow: 0px 0px 10px 0px #f15757;
		}
	}
}

.cons-sidebar-setting {
	position: relative;
	z-index: 10;
	margin-top: -30px;
	padding: 0 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid #26a4ed;
	background: white;
}

.cons-sidebar-setting-status-inputs {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	margin-bottom: 5px;
}

.custom-checkbox {
	display: inline-block;
	margin-right: 10px;
}

.custom-checkbox input[type='checkbox'] {
	display: none;
}

.custom-checkbox label {
	display: inline-block;
	padding: 0 4px;
	border: 1px solid #7D8485;
	cursor: pointer;
  }

.custom-checkbox .custom-checkbox-0:checked + label {
	background-color: #D4B851;
	border-color: #D4B851;
	color: #fff;
}

.custom-checkbox .custom-checkbox-4:checked + label {
	background-color: #8659E8;
	border-color: #8659E8;
	color: #fff;
}

.custom-checkbox .custom-checkbox-1:checked + label {
	background-color: #28bfa1;
	border-color: #28bfa1;
	color: #fff;
}

.custom-checkbox .custom-checkbox-2:checked + label {
	background-color: #f95e2eec;
	border-color: #f95e2eec;
	color: #fff;
}

.custom-checkbox .custom-checkbox-3:checked + label {
	background-color: #607d8b;
	border-color: #607d8b;
	color: #fff;
}

.custom-checkbox .custom-checkbox-5:checked + label {
	background-color: #ffeb3b;
	border-color: #ffeb3b;
	color: #000;
}

.custom-checkbox .custom-checkbox-6:checked + label {
	background-color: #70f800;
	border-color: #70f800;
	color: #000;
}

.cons-sidebar-setting-location-selector {
	border-radius: 5px;
    border: 2px solid #7d8485;
	margin-bottom: 15px;
}

.cons-sidebar-setting-buttons {
	margin-bottom: 15px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	
	> button {
		margin-left: 15px;
		padding: 5px 10px;
		background-color: #DFF3FF;
		font-weight: 600;
		border-radius: 4px;
	}
}

.cons-sidebar-setting-status > h5, .cons-sidebar-setting-location > h5 {
	color: #26A4ED;
	font-weight: 300;
	font-size: 14px;
}