.monthly-payment-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.monthly-payment-block-text {
  width: 100%;
  // max-width: 560px;
  text-align: center;
  margin-bottom: 25px;

  > p {
    margin: 0;
    margin-bottom: 10px;
  }
}

.monthly-payment-block-title {
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  color: #26a4ed;
  text-align: center;
}

.monthly-payment-block {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthly-payment-img {
  margin-bottom: 50px;
}

.monthly-payment-text {
  font-weight: bold;
  font-size: 28px;
}

.monthly-payment-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
}

.monthly-payment-yes {
  margin-right: 25px;
}

.monthly-payment-yes,
.monthly-payment-thinking {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 280px;
  color: #26a4ed;
  border: 1px solid #26a4ed;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
}

.monthly-payment-thinking {
  background-color: white;
}

.monthly-payment-yes {
  background-color: #dff3ff;
  border: 1px solid #26a4ed;
}

.monthly-payment-block-details {
  font-size: 30px;
  font-weight: bold;
  text-align: justify;
}

@media (max-width: 800px) {
  .payment-block-thinking-button {
    width: 200px;
  }

  .payment-block-yes-button {
    width: 200px;
  }

  .monthly-payment-block-text > p {
    text-align: center;
  }

  .monthly-payment-block-title {
    padding: 20px 0;
  }

  .monthly-payment-block-details {
    font-size: 20px;
  }
}

@media (max-width: 450px) {
	.monthly-payment-wrapper {
		height: auto;
		padding: 20px 0;
	}
  .monthly-payment-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .monthly-payment-buttons > button {
    margin: 5px 0;
  }
}
