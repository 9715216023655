.sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 55px);
    position: fixed;
    left: 0;
    width: 335px;
    overflow: auto;

    .nav-item {
        color: #7D8485;
        text-decoration: none;
    }

    .nav-item-info {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 2px;
        padding: 30px 0 30px 20px;
         
        > svg {
            padding-right: 20px;
            box-sizing: unset !important;
        }
    }

    .sidebar-active {
        color: #26A4ED !important;
    }
}