.main-header {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 55px;
    background: #DFF3FF;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    align-items: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.header-nav-item {
    padding: 5px;
    font-size: 12px;
    text-decoration: none;
    margin: 0 20px;

    .header-nav-item-info {
        text-transform: uppercase;
        color: #212529;
    }
}

.header-active {
    border: 1px solid black;
}