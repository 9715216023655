.signature-button {
	font-size: 22px;
	color: #26a4ed;
	background: white;
	padding: 5px 10px;
	border: 1px solid #26a4ed;
	margin-bottom: 10px;
	border-radius: 10px;
	// color: #f15757;
}

.modal {
	padding: 20px;
	background-color: white;
	border: 1px solid #26a4ed;
}

.modal-header {
	width: 100%;
	margin: 0;
	margin-bottom: 25px;
}

.sigCanvas {
	border: 1px solid black;
	// width: 250px;
	// min-height: 100px;
	margin-bottom: 25px;
}

.popup-overlay {
	background: rgba(0, 0, 0, 0.5);
}

.buttons-block {
	display: flex;
	justify-content: center;
	padding: 0 25px;
}

.signature-img {
	margin-bottom: 10px;
}

.save-button, .clear-button, .close-button {
	padding: 5px 10px;
	font-size: 20px;
	font-weight: bold;
	margin: 0 15px;
	background: white;
	// cursor: pointer;
	border-radius: 10px;
	color: #26a4ed;
	border: 1px solid #26a4ed;
}

// .save-button {
// 	border: 1px solid #26a4ed;
// }

// .clear-button {
// }

.close-button {
	border: 1px solid #f15757;
	color: #f15757;
}