.present {
	width: 100%;
	display: flex;
	justify-content: space-between;
	overflow-y: scroll;
}

.present-details {
	width: 60%;
}

.present-details-title {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0;
}

.present-details-title-text {
	// display: flex;
	// text-align: center;
}

.present-details-addons-payment,
.present-details-addons-paying {
	width: 100%;
}

.presentation-input {
	width: 100%;
	max-width: 130px;
}

.rs-slider-graduator > ol > li:last-child::after,
.rs-slider-graduator > ol > li::before {
	background-color: #26a4ed !important;
	border: none !important;
}

.rs-input-group > .rs-input,
.rs-input-group > .rs-auto-complete {
	z-index: 0;
}

.rs-slider-handle::before {
	width: 25px;
	height: 25px;
	top: -5px;
}

.rs-slider-bar {
	background-color: #26a4ed !important;
}

.present-details-title-header {
	font-size: 36px;
	font-weight: bold;
	margin-right: 30px;
	margin-bottom: 20px;
}

.present-details-title-info {
	font-size: 24px;
}

.present-details-info {
	width: 100%;
	margin-bottom: 30px;
}

.present-details-info-block {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 10px 50px;
	border-bottom: 2px solid #bcbdbe;

	> p {
		margin: 0;
	}
}

.present-details-addons {
	width: 100%;
	padding: 15px 20px;
	border: 2px solid #b2e1ff;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.present-details-addons-list {
	width: 100%;
	padding-bottom: 25px;

	> h5 {
		color: #26a4ed;
		margin: 0;
		margin-bottom: 15px;
		font-size: 20px;
		font-weight: bold;
	}
}

.present-details-addons-list-addon {
	width: 250px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background: #f3f3f3;
	margin-bottom: 10px;
}

.present-details-addons-list-addon-right-fees {
	color: #26a4ed;
}

.present-details-addons-list-addon-req {
	padding: 0 5px;
	color: white;
	background-color: #26a4ed;
}

.present-details-addons-invest {
	display: flex;
	padding: 20px 25px;
	color: #26a4ed;
	font-weight: bold;
	font-size: 24px;
	border: 2px solid #b2e1ff;
	margin-bottom: 20px;
}

.present-details-addons-invest-title {
	margin-right: 10px;
}

.present-details-addons-calc {
	border: 2px solid #b2e1ff;
	border-top: none;
	padding: 15px 20px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.present-details-addons-calc-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.present-details-addons-calc-block-title {
	color: #26a4ed;
	margin-bottom: 5px;
}

.present-details-notes {
	width: 100%;
	margin-bottom: 35px;
}

.present-details-notes-title {
	width: 100%;
	font-size: 34px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 20px;
	margin-top: 20px;
}

.present-details-notes-text {
	width: 100%;
	margin-bottom: 10px;
}

.last {
	margin-bottom: 35px;
}

.present-details-notes-accept {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.present-details-notes-accept-checkbox {
	width: 30px;
	height: 30px;
	outline: 3px solid #26a4ed;
	border: none;
	margin-right: 15px;
}

.present-details-notes-accept-checkbox:checked {
	background-color: #26a4ed;
}

input[type='checkbox' i] {
	background-color: #26a4ed;
}

.present-details-notes-accept-title {
	font-size: 20px;
	font-weight: bold;
	color: #26a4ed;
}

.present-details-contacts {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	font-size: 14px;
	margin-bottom: 25px;
}

.present-details-buttons {
	width: 100%;
	display: flex;
	justify-content: center;
	padding-bottom: 25px;
}

.present-details-share-button,
.present-details-download-button,
.present-details-next-button {
	background: none;
	outline: none;
	font-size: 24px;
	font-weight: bold;
	color: #26a4ed;
	padding: 15px 20px;
	margin: 0 10px;
	border-radius: 10px;
}

.present-details-share-button,
.present-details-download-button {
	border: 3px solid #26a4ed;
	text-decoration: none;
}

.present-details-next-button {
	background: #dff3ff;
}

.present-close {
	margin: 15px 15px 0 0;
	cursor: pointer;
}

.popup {
	display: flex;
	position: absolute;
	width: calc(100% - 280px);
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
	justify-content: center;
	align-items: center;
}

.popup-question {
	display: flex;
	position: absolute;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
	justify-content: center;
	align-items: center;
}

.hide-popup {
	display: none;
}

.input-block {
	padding: 20px;
	width: 550px;
	// height: 100%;
	display: flex;
	align-items: center;
	// justify-content: center;
	flex-direction: column;
	background-color: white;
}

.input-block > textarea {
	margin-bottom: 15px;
	height: 100px;
	width: 80%;
}

.input-block > p,
h5 {
	margin: 0;
}

.popup-close {
	width: 100%;
	margin-right: 10px;
	margin-bottom: 10px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	// height: 100vh;
}

.popup-close > svg {
	cursor: pointer;
}

.share-button {
	background: none;
	outline: none;
	font-size: 24px;
	font-weight: bold;
	color: #26a4ed;
	padding: 10px 20px;
	margin: 0 10px;
	border-radius: 10px;
	border: 3px solid #26a4ed;
	text-decoration: none;
}

.popup-header {
	font-size: 18px;
	margin-bottom: 15px;
}

.example {
	opacity: 0.5;
	padding-bottom: 20px;
	font-size: 15px;
	font-style: italic;
}

input[type='checkbox']:checked + .present-details-notes-accept-checkbox:before {
	background-color: #26a4ed;
}

.active-section-wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 30px;
}

.active-section {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	position: relative;
	right: 0;
	// width: 100%;
	height: 25px;
	border: 3px solid black;
	border-bottom: none;
	margin-bottom: -15px;
}

.active-section > p {
	margin: 0;
	margin-top: -25px;
}

.present-info-complex-elements-card {
	width: 45%;
	padding: 15px 30px;
	border: 3px solid #dff3ff;
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	background-color: #f3f3f3;
	margin-bottom: 10px;
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.present-info-complex-elements-card-sub {
	display: flex;
	align-items: center;
}

.present-info-complex-elements-card-toggle {
	margin-right: 10px;
}

.present-info-complex-elements-card-main-title,
.present-info-complex-elements-card-main-price {
	font-size: 13px;
}

.present-info-complex-elements-card-required,
.present-info-complex-elements-card-optional {
	font-size: 13px;
	padding: 3px;
	padding-top: 1px;
	color: #fff;
	margin-right: -40px;
}

.present-info-complex-elements-card-required {
	background-color: #26a4ed;
}

.present-info-complex-elements-card-optional {
	background-color: #7d8485;
}

@media (max-width: 980px) {
	.present-details {
		width: 85%;
	}
}

@media (max-width: 500px) {
	.present-details-addons-calc {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 20px;
	}

	.present-details-title {
		flex-direction: column;
		text-align: center;
	}

	.present-details-title-header {
		margin-right: 0;
	}

	.present-details-info-block {
		padding: 10px 0;
	}

	.present-info-complex-elements-card {
		width: 75%;
	}
	
	.present-details-addons-invest {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.present-details-addons-invest-title {
		text-align: center;
	}

	.active-section > p {
		font-size: 12px;
	}
}

@media (max-width: 425px) {
	.active-section {
		display: none;
	}
}

@media (max-width: 350px) {
	.present-info-complex-elements-card {
		width: 90%;
	}
}





// &-complex {
// 	margin-bottom: 20px;

// 	&-elements {
// 		padding: 15px 30px;
// 		border: 3px solid #dff3ff;
// 		margin-bottom: 15px;

// 		&-card {
// 			width: 35%;
// 		}

// 		&-card-edit {
// 			width: 45%;
// 			max-width: 500px;
// 		}

// 		&-card,
// 		&-card-edit {
// 			display: flex;
// 			justify-content: space-between;
// 			align-items: center;
// 			padding: 5px;
// 			background-color: #f3f3f3;
// 			margin-bottom: 10px;
// 			box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);

// 			&-main {
// 				&-title {
// 					font-size: 13px;
// 				}

// 				&-price {
// 					font-size: 13px;
// 				}
// 			}

// 			&-required,
// 			&-optional {
// 				font-size: 13px;
// 				padding: 3px;
// 				padding-top: 0;
// 				color: #fff;
// 				margin-right: -40px;
// 			}

// 			&-required {
// 				background-color: #26a4ed;
// 			}

// 			&-required-edit {
// 				display: flex;
// 				align-items: center;
// 				justify-content: center;
// 				margin-right: 10px;

// 				> p {
// 					margin: 0;
// 					margin-right: 10px;
// 					font-size: 13px;
// 					opacity: 0.7;
// 				}

// 				> svg {
// 					// margin-left: 25px;
// 					cursor: pointer;
// 				}
// 			}

// 			&-optional {
// 				background-color: #7d8485;
// 			}
// 		}

// 		&-container {
// 			width: 100%;
// 			display: flex;
// 			justify-content: flex-end;

// 			&-button {
// 				width: fit-content;
// 				font-weight: bold;
// 				padding: 10px 15px;
// 				border-radius: 4px;
// 				background-color: #dff3ff;
// 			}
// 		}
// 	}
// }
