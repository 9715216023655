.monthly-payment-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthly-payment-form-header {
  font-size: 30px;
  margin-bottom: 10px;
}

.monthly-payment-form-subheader {
  font-size: 28px;
  margin-top: 0;
  margin-bottom: 50px;
}

.monthly-payment-form-title {
  font-size: 28px;
  color: #26a4ed;
  margin-top: 0;
  margin-bottom: 60px;
}

.monthly-payment-form {
  padding: 25px 25px 0 25px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.exp-date-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 25px
}

.exp-date-title {
  margin-bottom: 10px;
  margin-top: 0;
}

.exp-date-block {
  width: 45%;
}

.monthly-payment-name-section {
  width: 100%;
  border: 1px solid #26a4ed;
  padding: 20px;
  margin-bottom: 35px;
}

.monthly-payment-input-block-wrapper {
  display: flex;
}

.monthly-payment-input-block-half {
  width: 50%;
  padding: 0 10px;
}

.monthly-payment-input-block-full {
  padding: 0 10px;
}

.monthly-payment-input,
.monthly-payment-number-input {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #7d8485;
  transition: border-color 0.3s ease;
}

.monthly-payment-input:hover,
.monthly-payment-number-input:hover {
  border-color: #26a4ed;
}

.monthly-payment-input:focus,
.monthly-payment-number-input:focus {
  border-color: #26a4ed;
  outline: none;
}

.monthly-payment-title-block {
  margin-left: 10px;
  margin-top: -38.5px;
  padding: 5px 10px;
  border: 1px solid #26a4ed;
  width: fit-content;
  background-color: white;
  font-weight: bold;
  font-size: 18px;
  // text-transform: uppercase;
}

.monthly-payment-name-section-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.monthly-payment-name-section-half {
  width: 48%;
  border: 1px solid #26a4ed;
  padding: 20px;
  margin-bottom: 35px;
}

.monthly-payment-input-block-full {
  width: 100%;
}

.monthly-payment-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.monthly-payment-checkbox {
  margin-right: 15px;
  width: 15px;
  height: 15px;
}

.monthly-payment-checkbox-text {
  margin-right: 15px;
}

.monthly-payment-input-title,
.monthly-payment-number-input-title {
  font-weight: bold;
  opacity: 0.75;
  font-size: 18px;
}

.monthly-payment-form-button {
  width: 300px;
  height: 50px;
  background-color: white;
  border: 2px solid #26a4ed;
  color: #26a4ed;
  font-weight: bold;
  font-size: 18px;
}

.monthly-payment-left,
.monthly-payment-right {
  width: 45%;
}

.monthly-payment-right {
  display: flex;
  flex-direction: column;
}

.monthly-payment-account-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.monthly-payment-next {
  padding: 15px 20px;
  margin-bottom: 15px;
  background-color: #dff3ff;
  color: #26a4ed;
  border-radius: 10px;
  border: 1px solid #26a4ed;
  font-weight: bold;
  font-size: 18px;
}

@media (max-width: 930px) {
  .monthly-payment-account-info {
    flex-direction: column;
	align-items: center;
  }

  .monthly-payment-left, .monthly-payment-right {
	width: 100%;
  }
}

@media (max-width: 610px) {
  .payment-form-buttons {
	display: flex;
	flex-direction: column;
  }

  .monthly-payment-form-header {
	text-align: center;
  }

  .monthly-payment-input-block-wrapper {
	flex-direction: column;
  }

  .monthly-payment-input-block-half {
	width: 100%;
  }
  
  .monthly-payment-name-section-wrapper {
	flex-direction: column;
  }
  
  .monthly-payment-name-section-half {
	width: 100%;
  }
}

@media (max-width: 475px) {
  .payment-form-buttons {
	display: flex;
	flex-direction: column;
  }

  .sigCanvas {
	width: 220px;
	height: 70.4px;
  }
}
