.profile {
	&-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding: 50px 0;
		width: 100%;
		height: calc(100vh - 55px);
		overflow: auto;
	}

	&-main {
		display: flex;
		justify-content: space-between;
		width: 20%;
		margin-bottom: 50px;
		min-width: 280px;

		&-avatar {
			width: 100px;
			height: 100px;
			background-color: #fff1c8;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 25px;
			font-weight: bold;
			color: #d3ab34;
		}

		&-info {
			display: flex;
			flex-direction: column;
			justify-content: center;

			&-title {
				margin-top: 0;
			}
		}
	}

	&-confirmation {
		width: 45%;
        margin-bottom: 50px;

		&-header {
			width: fit-content;
			font-weight: bold;
			font-size: 13px;
			color: #26a4ed;
			border: 3px solid #dff3ff;
			padding: 5px;
			margin-left: 15px;
			border-bottom: none;
		}

		&-details {
			padding: 25px 50px;
			display: flex;
			flex-direction: column;
			border: 3px solid #dff3ff;

			&-title {
				font-size: 14px;
				letter-spacing: 1.2px;
				color: #26a4ed;
				padding-bottom: 10px;
			}

			&-input {
				border-radius: 5px;
				border: 2px solid #7d8485;
				color: #212529;
				font-size: 13px;
				height: 35px;
				margin-bottom: 50px;
			}

			&-button {
				width: 100%;
				display: flex;
				justify-content: center;

				&-content {
					width: 70%;
					padding: 15px 20px;
					color: #26a4ed;
					border-radius: 10px;
					background: #dff3ff;
				}
			}
		}
	}

	&-logout {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
        width: 25%;
        background-color: #FFDFDF;
        cursor: pointer;
        border-radius: 10px;

		&-icon {
			width: 40px;
			height: 40px;
		}

		&-text {
            color: #F15757;
            font-size: 20px;
            letter-spacing: 1.8px;
		}

        &-empty {
			width: 40px;
			height: 40px;
		}
	}
}

.code_popup {
	display: flex;
	position: absolute;
	width: 100%;
	height: calc(100vh - 55px);
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
	justify-content: center;
	align-items: center;
}

.input-block {
	& input {
		// width: 75%;
		margin-bottom: 15px;
	}
}